import { VNodeChild } from 'vue'
import { createTypes, toType } from 'vue-types'

export type VueNode = VNodeChild | JSX.Element

export default class propTypes extends createTypes() {
  static get style() {
    return toType('style', {
      type: [String, Object],
      default: () => undefined,
    })
  }
  static get VNodeChild() {
    return toType('VNodeChild', {
      type: [String, Object],
      default: () => undefined,
    })
  }
}
