import Layout from '@/layout/index.vue'

import { $t } from '@/plugins/i18n'

const remainingRouter = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login.vue'),
    meta: {
      title: $t('menus.hslogin'),
      isHidden: true,
      i18n: true,
      rank: 101,
    },
  },
  {
    path: '/redirect',
    name: 'redirect',
    component: Layout,
    meta: {
      icon: 'home-filled',
      title: $t('menus.hshome'),
      i18n: true,
      isHidden: true,
      rank: 104,
    },
    children: [
      {
        path: '/redirect/:path(.*)',
        name: 'redirect',
        component: () => import('@/views/redirect.vue'),
      },
    ],
  },
]

export default remainingRouter
