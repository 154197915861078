<script lang="tsx">
import { useAppStoreHook } from '@/store/modules/app'
import { useMultiTagsStore } from '@/store/modules/multiTags'
import { useSettingStoreHook } from '@/store/modules/settings'
import { computed, defineComponent, getCurrentInstance, h, reactive } from 'vue'
import { useI18n } from 'vue-i18n'
import { routerArrays, setType } from '../types'
import fullScreen from '@/assets/icons/full-screen.svg?component'
import exitScreen from '@/assets/icons/exit-screen.svg?component'

import navbar from './navbar.vue'
import tag from './tag/index.vue'
import Horizontal from './sidebar/horizontal.vue'

export default defineComponent({
  setup() {
    const instance = getCurrentInstance()!.appContext.app.config.globalProperties
    const pureSetting = useSettingStoreHook()

    const layout = computed(() => {
      // 路由
      if (
        useMultiTagsStore().multiTagsCache &&
        (!instance.$storage.tags || instance.$storage.tags.length === 0)
      ) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        instance.$storage.tags = [...routerArrays]
      }
      // 国际化
      if (!instance.$storage.locale) {
        // eslint-disable-next-line
        instance.$storage.locale = { locale: instance.$config?.Locale ?? 'zh' }
        useI18n().locale.value = instance.$config?.Locale ?? 'zh'
      }
      // 导航
      if (!instance.$storage.layout) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        instance.$storage.layout = {
          layout: instance.$config?.Layout ?? 'vertical',
          theme: instance.$config?.Theme ?? 'default',
          darkMode: instance.$config?.DarkMode ?? false,
          sidebarStatus: instance.$config?.SidebarStatus ?? true,
          epThemeColor: instance.$config?.EpThemeColor ?? '#409EFF',
        }
      }
      // 灰色模式、色弱模式、隐藏标签页
      if (!instance.$storage.configure) {
        // eslint-disable-next-line
        instance.$storage.configure = {
          grey: instance.$config?.Grey ?? false,
          weak: instance.$config?.Weak ?? false,
          hideTabs: instance.$config?.HideTabs ?? false,
          showLogo: instance.$config?.ShowLogo ?? true,
          showModel: instance.$config?.ShowModel ?? 'smart',
          multiTagsCache: instance.$config?.MultiTagsCache ?? false,
        }
      }
      return instance.$storage?.layout.layout
    })

    const set: setType = reactive({
      sidebar: computed(() => {
        return useAppStoreHook().sidebar
      }),

      device: computed(() => {
        return useAppStoreHook().device
      }),

      fixedHeader: computed(() => {
        return pureSetting.fixedHeader
      }),

      classes: computed(() => {
        return {
          hideSidebar: !set.sidebar.opened,
          openSidebar: set.sidebar.opened,
          withoutAnimation: set.sidebar.withoutAnimation,
          mobile: set.device === 'mobile',
        }
      }),

      hideTabs: computed(() => {
        return instance.$storage?.configure.hideTabs
      }),
    })

    function onFullScreen() {
      pureSetting.hiddenSideBar
        ? pureSetting.changeSetting({ key: 'hiddenSideBar', value: false })
        : pureSetting.changeSetting({ key: 'hiddenSideBar', value: true })
    }

    return {
      set,
      layout,
      onFullScreen,
      pureSetting,
    }
  },
  render() {
    return h(
      'div',
      {
        class: { 'fixed-header': this.set.fixedHeader },
        style: [
          this.set.hideTabs && this.layout.includes('horizontal')
            ? 'box-shadow: 0 1px 4px rgb(0 21 41 / 8%);'
            : '',
        ],
      },
      {
        default: () => [
          !this.pureSetting.hiddenSideBar && this.layout.includes('vertical')
            ? h(navbar)
            : h('div'),
          !this.pureSetting.hiddenSideBar && this.layout.includes('horizontal')
            ? h(Horizontal)
            : h('div'),
          h(
            tag,
            {},
            {
              default: () => [
                h(
                  'span',
                  { onClick: this.onFullScreen },
                  {
                    default: () => [
                      !this.pureSetting.hiddenSideBar ? h(fullScreen) : h(exitScreen),
                    ],
                  }
                ),
              ],
            }
          ),
        ],
      }
    )
  },
})
</script>
<style></style>
