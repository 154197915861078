import { storageLocal } from '@/utils/storage'
import { getCurrentInstance, ref, computed } from 'vue'

const instanceConfig = getCurrentInstance()?.appContext.app.config.globalProperties.$config
export default function getFillColor(): any {
  const layoutTheme =
    ref(storageLocal.getItem('rs-layout')) ||
    ref({
      layout: instanceConfig?.Layout ?? 'vertical',
      theme: instanceConfig?.Theme ?? 'default',
    })
  const fillColor = computed(() => {
    if (!layoutTheme.value.theme || layoutTheme.value.theme === 'light') {
      return '#333'
    } else {
      return '#fff'
    }
  })

  return {
    fillColor,
  }
}
