<template>
  <el-config-provider :locale="currentLocale" size="small">
    <router-view />
  </el-config-provider>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
// import en from 'element-plus/lib/locale/lang/en'
import dayjs from 'dayjs'
// 引入中文
import cn from 'dayjs/locale/zh-cn'
dayjs.locale({
  ...cn,
  weekStart: 1,
})

export default defineComponent({
  name: 'App',
  computed: {
    currentLocale() {
      // return this.$storage.locale?.locale === 'zh' ? zhCn : en
      return zhCn
    },
  },
})
</script>
<style lang="scss">
// 导入公共样式
@import './style/index.scss';
@import 'vxe-table/lib/style.css';
@import 'element-plus/dist/index.css';
</style>
