// 分转化为元 - 正则解决精度

export function regFenToYuan(fen: number | string) {
  if (!fen) {
    return toDecimal2(0)
  }
  fen = Number(fen)
  let num: string = fen.toString()
  num = fen * 0.01 + ''
  num += ''
  const reg = num.indexOf('.') > -1 ? /(\d{1,3})(?=(?:\d{3})+\.)/g : /(\d{1,3})(?=(?:\d{3})+$)/g
  num = num.replace(reg, '$1')
  num = toDecimal2(num) || ''
  return num
}

// 元转分 - 解决精度问题 yuan:要转换的钱，单位元； digit：转换倍数

export function regYuanToFen(yuan: number, digit: number) {
  let m = 0
  const s1 = yuan.toString()
  const s2 = digit.toString()
  try {
    m += s1.split('.')[1].length
    m += s2.split('.')[1].length
  } catch (e) {
    console.log('error', e)
  }
  return (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) / Math.pow(10, m)
}

// 判断是否最多两位小数，正负均可

export const checkTwoPointNum = (inputNumber: number | string) => {
  const partten = /^-?\d+\.?\d{0,2}$/
  return partten.test(inputNumber + '')
}

// 强制保留2位小数，如：2，会在2后面补上00.即2.00

export const toDecimal2 = (x: number | string) => {
  let f = parseFloat(x)
  if (isNaN(f)) {
    return false
  }
  f = Math.round((x as number) * 100) / 100
  let s = f.toString()
  let rs = s.indexOf('.')
  if (rs < 0) {
    rs = s.length
    s += '.'
  }
  while (s.length <= rs + 2) {
    s += '0'
  }
  return s
}

// 金额转为大写汉字

export const priceUppercase = (money: number | string) => {
  const cnNums = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'] //汉字的数字
  const cnIntRadice = ['', '拾', '佰', '仟'] //基本单位
  const cnIntUnits = ['', '万', '亿', '兆'] //对应整数部分扩展单位
  const cnDecUnits = ['角', '分', '毫', '厘'] //对应小数部分单位
  //var cnInteger = "整"; //整数金额时后面跟的字符
  let cnIntLast = '元' //整型完以后的单位
  // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
  const maxNum = 999999999999999.9999 //最大处理的数字

  let IntegerNum //金额整数部分
  let DecimalNum //金额小数部分
  let ChineseStr = '' //输出的中文金额字符串
  let parts //分离金额后用的数组，预定义
  if (!money) {
    return ''
  }
  money = parseFloat(money)
  if (money >= maxNum) {
    // $.alert('超出最大处理数字');
    return ''
  }
  if (money == 0) {
    //ChineseStr = cnNums[0]+cnIntLast+cnInteger;
    ChineseStr = cnNums[0] + cnIntLast
    //document.getElementById("show").value=ChineseStr;
    return ChineseStr
  }
  money = money.toString() //转换为字符串
  if (money.indexOf('.') == -1) {
    IntegerNum = money
    DecimalNum = ''
    cnIntLast = '元整'
  } else {
    parts = money.split('.')
    IntegerNum = parts[0]
    DecimalNum = parts[1].substr(0, 4)
  }
  let zeroCount = 0
  let IntLen = 0
  let n
  let p
  let m
  let q
  let decLen = 0
  if (parseInt(IntegerNum, 10) > 0) {
    //获取整型部分转换
    zeroCount = 0
    IntLen = IntegerNum.length
    for (let i = 0; i < IntLen; i++) {
      n = IntegerNum.substr(i, 1)
      p = IntLen - i - 1
      q = p / 4
      m = p % 4
      if (n == '0') {
        zeroCount++
      } else {
        if (zeroCount > 0) {
          ChineseStr += cnNums[0]
        }
        zeroCount = 0 //归零
        ChineseStr += cnNums[parseInt(n)] + cnIntRadice[m]
      }
      if (m == 0 && zeroCount < 4) {
        ChineseStr += cnIntUnits[q]
      }
    }
    ChineseStr += cnIntLast
    //整型部分处理完毕
  }
  if (DecimalNum != '') {
    //小数部分
    decLen = DecimalNum.length
    for (let i = 0; i < decLen; i++) {
      n = DecimalNum.substr(i, 1)
      if (n != '0') {
        ChineseStr += cnNums[Number(n)] + cnDecUnits[i]
      }
    }
  }
  if (ChineseStr == '') {
    //ChineseStr += cnNums[0]+cnIntLast+cnInteger;
    ChineseStr += cnNums[0] + cnIntLast
  }
  /* else if( DecimalNum == '' ){
	              ChineseStr += cnInteger;
	              ChineseStr += cnInteger;
	          } */
  return ChineseStr
}

// 格式化金钱，三位加一个逗号

export const priceToThousands = (num: number | string) => {
  if (num) {
    num = toDecimal2(num) || ''
    //将num中的$,去掉，将num变成一个纯粹的数据格式字符串
    num = num.toString().replace(/\$|\,/g, '')
    //如果num不是数字，则将num置0，并返回
    if ('' == num || isNaN(Number(num))) {
      return 'Not a Number ! '
    }
    //如果num是负数，则获取她的符号
    const sign = num.indexOf('-') > 0 ? '-' : ''
    //如果存在小数点，则获取数字的小数部分
    let cents = num.indexOf('.') > 0 ? num.substr(num.indexOf('.')) : ''
    cents = cents.length > 1 ? cents : '' //注意：这里如果是使用change方法不断的调用，小数是输入不了的
    //获取数字的整数数部分
    num = num.indexOf('.') > 0 ? num.substring(0, num.indexOf('.')) : num
    //如果没有小数点，整数部分不能以0开头
    if ('' == cents) {
      if (num.length > 1 && '0' == num.substr(0, 1)) {
        return 'Not a Number ! '
      }
    }
    //如果有小数点，且整数的部分的长度大于1，则整数部分不能以0开头
    else {
      if (num.length > 1 && '0' == num.substr(0, 1)) {
        return 'Not a Number ! '
      }
    }
    //针对整数部分进行格式化处理，这是此方法的核心，也是稍难理解的一个地方，逆向的来思考或者采用简单的事例来实现就容易多了
    /*
		  也可以这样想象，现在有一串数字字符串在你面前，如果让你给他家千分位的逗号的话，你是怎么来思考和操作的?
		  字符串长度为0/1/2/3时都不用添加
		  字符串长度大于3的时候，从右往左数，有三位字符就加一个逗号，然后继续往前数，直到不到往前数少于三位字符为止
		 */
    for (let i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++) {
      num =
        num.substring(0, num.length - (4 * i + 3)) + ',' + num.substring(num.length - (4 * i + 3))
    }
    //将数据（符号、整数部分、小数部分）整体组合返回
    return sign + num + cents
  }
}
