import { createApp, Directive } from 'vue'
import router from './router'
import App from './App.vue'
import { setupStore } from '@/store'
import { getServerConfig } from './config'
import usI18n from '../src/plugins/i18n'
import { MotionPlugin } from '@vueuse/motion'
import { injectResponsiveStorage } from '@/utils/storage/responsive'
import * as directives from '@/directives'
import 'xe-utils'
import VXETable from 'vxe-table'
import tableExport from 'vxe-table-plugin-export-xlsx'
import XEUtils from 'xe-utils'
import 'virtual:windi.css'
// Register icon sprite
import 'virtual:svg-icons-register'

import Icon from '@/components/Icon'
import FormCreate from '@form-create/element-ui'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import { regFenToYuan } from './utils/money'

VXETable.use(tableExport)
VXETable.setup({
  size: 'small',
  pager: {
    size: null,
    autoHidden: false,
    perfect: false,
    pageSize: 10,
    pagerCount: 7,
    pageSizes: [10, 15, 20, 50, 100],
    layouts: [
      'PrevJump',
      'PrevPage',
      'Jump',
      'PageCount',
      'NextPage',
      'NextJump',
      'Sizes',
      'Total',
    ],
  },
})
VXETable.formats.mixin({
  // 格式化日期，默认 yyyy-MM-dd
  formatDate({ cellValue }, format) {
    return XEUtils.toDateString(cellValue, format || 'yyyy-MM-dd')
  },
  formatMoney({ cellValue }) {
    return regFenToYuan(cellValue) + ''
  },
})

async function bootstrap() {
  const app = createApp(App)

  for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }
  Object.keys(directives).forEach((key) => {
    app.directive(key, (directives as { [key: string]: Directive })[key])
  })
  app.component('Icon', Icon)
  // Configure store
  getServerConfig(app).then(async (config) => {
    injectResponsiveStorage(app, config)
    app.use(VXETable)
    app.use(FormCreate)
    setupStore(app)
    app.use(router).use(MotionPlugin).use(usI18n)
    await router.isReady()
    app.mount('#app')
  })
}

bootstrap()
