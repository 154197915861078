<script setup lang="ts">
import Logo from './logo.vue'
import { emitter } from '@/utils/mitt'
import SidebarItem from './sidebarItem.vue'
import { storageLocal } from '@/utils/storage'
import { RouteComponent, RouteRecordRaw, useRoute, useRouter } from 'vue-router'
import { computed, ref, onBeforeMount } from 'vue'
import { useAppStoreHook } from '@/store/modules/app'
import { usePermissionStoreHook } from '@/store/modules/permission'
import { childrenType } from '@/layout/types'

const route = useRoute()
const pureApp = useAppStoreHook()
const router = useRouter().options.routes
const showLogo = ref(storageLocal.getItem('rs-configure')?.showLogo ?? true)
const isCollapse = computed(() => {
  return !pureApp.getSidebarStatus
})
const activeMenu = computed((): string => {
  const { meta, path } = route
  if (meta.activeMenu) {
    // @ts-ignore
    return meta.activeMenu
  }
  return path
})

const menuSelect = (indexPath: string): void => {
  let parentPath = ''
  let parentPathIndex = indexPath.lastIndexOf('/')
  if (parentPathIndex > 0) {
    parentPath = indexPath.slice(0, parentPathIndex)
  }
  // 找到当前路由的信息
  // eslint-disable-next-line no-inner-declarations
  function findCurrentRoute(routes: readonly RouteRecordRaw[]) {
    return routes.map((item: RouteRecordRaw) => {
      if (item.path === indexPath) {
        // 切换左侧菜单 通知标签页
        emitter.emit('changLayoutRoute', {
          indexPath,
          parentPath,
        })
      } else {
        if (item.children) findCurrentRoute(item.children)
      }
    })
  }
  findCurrentRoute(router)
}

onBeforeMount(() => {
  emitter.on('logoChange', (key) => {
    showLogo.value = key
  })
})
</script>

<template>
  <div :class="['sidebar-container', showLogo ? 'has-logo' : '']">
    <Logo v-if="showLogo" :collapse="isCollapse" />
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        :default-active="activeMenu"
        :collapse="isCollapse"
        unique-opened
        router
        :collapse-transition="false"
        mode="vertical"
        class="outer-most"
        @select="menuSelect"
      >
        <sidebar-item
          v-for="menu in usePermissionStoreHook().wholeMenus"
          :key="(menu as unknown as childrenType ).path"
          :item="(menu as unknown as childrenType )"
          class="outer-most"
          :base-path="(menu as unknown as childrenType ).path"
        />
      </el-menu>
    </el-scrollbar>
  </div>
</template>
