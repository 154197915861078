import Cookies from 'js-cookie'
import { storageLocal } from '@/utils/storage'

const TokenKey = 'authorized-token'

// 获取token
export function getToken() {
  return storageLocal.getItem(TokenKey) || ''
}

// 设置token以及过期时间（cookies）
export function setToken(accessToken: string) {
  storageLocal.setItem(TokenKey, accessToken)
}

// 删除token
export function removeToken() {
  storageLocal.removeItem(TokenKey)
  Cookies.get('SHANGZHOU-SERVER-ADMIN')
  console.log('Cookies: ', Cookies.get('SHANGZHOU-SERVER-ADMIN'))
}
