import { toRouteType } from './types'
import NProgress from '@/utils/progress'
import { constantRoutes } from './modules'
import remainingRouter from './modules/remaining'
import { useMultiTagsStoreHook } from '@/store/modules/multiTags'
import { Router, RouteMeta, createRouter, RouteRecordName } from 'vue-router'
import { getHistoryMode, handleAliveRoute } from './utils'
import { getToken } from '@/utils/auth'
import { useUserStoreHook } from '@/store/modules/user'
const whiteList = ['/login']
const userStore = useUserStoreHook()
// 创建路由实例
export const router: Router = createRouter({
  history: getHistoryMode(),
  routes: constantRoutes.concat(...remainingRouter),
  strict: true,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      if (savedPosition) {
        return savedPosition
      } else {
        if (from.meta.saveSrollTop) {
          const top: number = document.documentElement.scrollTop || document.body.scrollTop
          resolve({ left: 0, top })
        }
      }
    })
  },
})

router.beforeEach((to: toRouteType, _from, next) => {
  if (to.meta?.keepAlive) {
    const newMatched = to.matched
    handleAliveRoute(newMatched, 'add')
    // 页面整体刷新和点击标签页刷新
    if (_from.name === undefined || _from.name === 'redirect') {
      handleAliveRoute(newMatched)
    }
  }
  NProgress.start()
  try {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else if (!getToken()) {
      throw new Error('token不存在，无法跳转')
    } else if (userStore.userInfo) {
      next()
    } else {
      // token 存在，但是用户信息不存在，则获取（页面刷新时）
      userStore
        .getUserInfo()
        .then((resp) => {
          if (to.matched.length === 0) {
            router.push(to.path)
          } else {
            next()
          }
        })
        .catch((e) => {
          throw new Error('获取用户信息异常')
        })
    }
  } catch (error) {
    //出现异常则退出登录
    useUserStoreHook().logout()
    NProgress.done()
  }
})

router.afterEach(() => {
  NProgress.done()
})

function handTag(path: string, parentPath: string, name: RouteRecordName, meta: RouteMeta): void {
  useMultiTagsStoreHook().handleTags('push', {
    path,
    parentPath,
    name,
    meta,
  })
}

export default router
