import { defineStore } from 'pinia'
import { store } from '@/store'
import { cacheType, permissionTyp } from './types'
import { cloneDeep } from 'lodash-es'
import { RouteConfigs } from '@/layout/types'
import { constantMenus } from '@/router/modules'
import { ascending, filterTree } from '@/router/utils'
export const usePermissionStore = defineStore({
  id: 'pure-permission',
  state: (): permissionTyp => ({
    // 静态路由生成的菜单
    constantMenus,
    // 整体路由生成的菜单（静态、动态）
    wholeMenus: [],
    // 深拷贝一个菜单树，与导航菜单不突出
    menusTree: [],
    buttonAuth: [],
    // 缓存页面keepAlive
    cachePageList: [],
  }),
  actions: {
    // 获取异步路由菜单
    asyncActionRoutes(routes: RouteConfigs[]) {
      this.wholeMenus = filterTree(ascending(this.constantMenus.concat(routes)))

      this.menusTree = cloneDeep(this.wholeMenus)

      const getButtonAuth = (arrRoutes: Array<RouteConfigs>) => {
        if (!arrRoutes || !arrRoutes.length) return
        arrRoutes.forEach((v: RouteConfigs) => {
          if (v.meta && v.meta.authority) {
            this.buttonAuth.push(...v.meta.authority)
          }
          if (v.children) {
            getButtonAuth(v.children)
          }
        })
      }

      getButtonAuth(this.wholeMenus as RouteConfigs[])
    },
    async changeSetting(routes: RouteConfigs[]) {
      await this.asyncActionRoutes(routes)
    },
    cacheOperate({ mode, name }: cacheType) {
      switch (mode) {
        case 'add':
          if (name) {
            this.cachePageList.push(name as string)
          }
          this.cachePageList = [...new Set(this.cachePageList)]
          break
        case 'delete':
          // eslint-disable-next-line no-case-declarations
          const delIndex = this.cachePageList.findIndex((v) => v === name)
          delIndex !== -1 && this.cachePageList.splice(delIndex, 1)
          break
      }
    },
    // 清空缓存页面
    clearAllCachePage() {
      this.cachePageList = []
    },
  },
})

export function usePermissionStoreHook() {
  return usePermissionStore(store)
}
