<template>
  <svg
    :class="['svg-icon', $attrs.class, spin && 'svg-icon-spin']"
    aria-hidden="true"
    :style="getStyle"
  >
    <use :xlink:href="iconName" :fill="color" />
  </svg>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import type { CSSProperties } from 'vue'

export default defineComponent({
  props: {
    name: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '#889aa4',
    },
    size: {
      type: [Number, String],
      default: 16,
    },
    spin: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    return {
      iconName: computed(() => `#icon-${props.name}`),
      getStyle: computed((): CSSProperties => {
        const { size } = props
        let s = `${size}`
        s = `${s.replace('px', '')}px`
        return {
          width: s,
          height: s,
        }
      }),
    }
  },
})
</script>

<style scope>
.svg-icon {
  width: 1em;
  height: 1em;
  fill: currentColor;
  vertical-align: middle;
}
.svg-icon-spin {
  animation: loadingCircle 1s infinite linear;
}
</style>
