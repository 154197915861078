import Layout from '@/layout/index.vue'
import { $t } from '@/plugins/i18n'

const reportRouter = {
  path: '/report',
  name: 'report',
  component: Layout,
  redirect: '/report/rush',
  meta: {
    icon: 'MilkTea',
    title: $t('menus.report'),
    i18n: true,
    rank: 5,
  },
  children: [
    {
      path: '/report/rush',
      name: 'reportRush',
      component: () => import('@/views/report/rush.vue'),
      meta: {
        title: $t('menus.report-rush'),
        i18n: true,
        icon: 'Calendar',
        rank: 501,
        isHidden: false,
      },
    },
    {
      path: '/report/collection',
      name: 'reportCollection',
      component: () => import('@/views/report/collection.vue'),
      meta: {
        title: $t('menus.report-collection'),
        i18n: true,
        icon: 'Calendar',
        rank: 502,
        isHidden: false,
      },
    },
    {
      path: '/report/sales',
      name: 'reportSales',
      component: () => import('@/views/report/sales.vue'),
      meta: {
        title: $t('menus.report-sales'),
        i18n: true,
        icon: 'Calendar',
        rank: 503,
        isHidden: false,
      },
    },
    {
      path: '/report/delivery',
      name: 'reportDelivery',
      component: () => import('@/views/report/delivery.vue'),
      meta: {
        title: $t('menus.report-delivery'),
        i18n: true,
        icon: 'Calendar',
        rank: 504,
        isHidden: false,
      },
    },
    {
      path: '/report/wastage',
      name: 'reportWastage',
      component: () => import('@/views/report/wastage.vue'),
      meta: {
        title: $t('menus.report-wastage'),
        i18n: true,
        icon: 'Calendar',
        rank: 505,
        isHidden: false,
      },
    },
  ],
}

export default reportRouter
