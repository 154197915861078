import { store } from '@/store'
import { defineStore } from 'pinia'
import { getConfig } from '@/config'
import { storageLocal } from '@/utils/storage'

export const useEpThemeStore = defineStore({
  id: 'pure-epTheme',
  state: () => ({
    epThemeColor: storageLocal.getItem('rs-layout')?.epThemeColor ?? getConfig().EpThemeColor,
  }),
  getters: {
    getEpThemeColor(): string {
      return this.epThemeColor
    },
  },
  actions: {
    setEpThemeColor(newColor: string) {
      const layout = storageLocal.getItem('rs-layout')
      this.epThemeColor = newColor
      layout.epThemeColor = newColor
      storageLocal.setItem('rs-layout', layout)
    },
  },
})

export function useEpThemeStoreHook() {
  return useEpThemeStore(store)
}
