import { defineStore } from 'pinia'
import { store } from '@/store'
import { getCompanyList, getOrgList, queryUseInfoByIds } from '@/api/sync'
import type { orgType, orgWithRegion } from './types'
import type { OrgInfo } from '@/api/model/Sync'

export const useOrgStore = defineStore({
  id: 'report-org',
  state: (): orgType => ({
    regionList: [],
    orgList: [],
    companyList: [],
  }),
  actions: {
    async getRegionList(regionIds?: string) {
      return new Promise<orgWithRegion[]>((resolve, reject) => {
        if (regionIds) {
          const ids = regionIds.split(',')
          console.log('ids: ', ids)
          Promise.all([queryUseInfoByIds(ids), getOrgList(ids)])
            .then((result) => {
              this.regionList = result[0]
              const orgs = result[1]
              this.orgList = orgs.map((item) => {
                return {
                  ...item,
                  regionName: this.regionList.find((r) => r.id === item.regionId)?.name ?? '',
                }
              })
              resolve(this.orgList)
            })
            .catch((error) => {
              reject(error)
            })
        } else {
          this.regionList = []
          this.orgList = []
        }
      })
    },
    async getAllCompany() {
      return new Promise<any[]>((resolve, reject) => {
        getCompanyList()
          .then((result) => {
            this.companyList = result
            resolve(this.companyList)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getCompanyByCode(code: string) {
      const target = this.companyList.find((company) => {
        return company['company_code'] === code
      })
      return target
    },
    getOrgInfoById(id: number | string) {
      return this.orgList.find((org) => {
        return org.orgId === id
      })
    },
    findRegionInfoById(id: number | string) {
      const target = this.regionList.find((region) => {
        return region.id === id
      })
      return target
    },
  },
})

export function useOrgStoreHook() {
  return useOrgStore(store)
}
