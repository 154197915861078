import Layout from '@/layout/index.vue'
import { $t } from '@/plugins/i18n'

const homeRouter = {
  path: '/',
  name: 'home',
  component: Layout,
  redirect: '/dashboard',
  meta: {
    icon: 'Menu',
    title: $t('menus.hshome'),
    i18n: true,
    rank: 0,
  },
  children: [
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('@/views/dashboard/index.vue'),
      meta: {
        title: $t('menus.hshome'),
        i18n: true,
      },
    },
  ],
}

export default homeRouter
