import axios from 'axios'
import { App } from 'vue'

let config: Record<string, any> = {}

const setConfig = (cfg?: unknown) => {
  config = Object.assign(config, cfg)
}

const getConfig = (key?: string): ServerConfigs => {
  if (typeof key === 'string') {
    const arr = key.split('.')
    if (arr && arr.length) {
      let data = config
      arr.forEach((v) => {
        if (data && typeof data[v] !== 'undefined') {
          data = data[v]
        } else {
          data = {}
        }
      })
      return data
    }
  }
  return config
}

// 获取项目动态全局配置
export const getServerConfig = async (app: App): Promise<ServerConfigs> => {
  app.config.globalProperties.$config = getConfig()
  return axios({
    baseURL: '',
    method: 'get',
    url: `${import.meta.env.VITE_PUBLIC_PATH}serverConfig.json`,
  })
    .then(({ data: config }) => {
      let $config = app.config.globalProperties.$config
      // 自动注入项目配置
      if (app && $config && typeof config === 'object') {
        $config = Object.assign($config, config)
        app.config.globalProperties.$config = $config
        // 设置全局配置
        setConfig($config)
      }
      // 设置全局baseURL
      app.config.globalProperties.$baseUrl = $config.baseURL
      return $config
    })
    .catch(() => {
      throw '请在public文件夹下添加serverConfig.json配置文件'
    })
}

export { getConfig, setConfig }
