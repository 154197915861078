<template>
  <div class="screen-full" @click="toggle">
    <svg-icon
      :title="isFullscreen ? $t('buttons.hsexitfullscreen') : $t('buttons.hsfullscreen')"
      :name="isFullscreen ? 'exit-screen' : 'full-screen'"
      style="color: #fff"
      :size="18"
    ></svg-icon>
  </div>
</template>
<script setup lang="ts">
import { SvgIcon } from '@/components/Icon'
import { useFullscreen } from '@vueuse/core'
const { isFullscreen, toggle } = useFullscreen()
</script>

<style lang="scss" scoped>
.screen-full {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
</style>
