<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'sidebarItem',
})
</script>
<script setup lang="ts" name="sidebarItem">
import { ref, PropType, nextTick, computed, CSSProperties, getCurrentInstance, Ref } from 'vue'
// import path from 'path'
import { childrenType } from '../../types'
import { useAppStoreHook } from '@/store/modules/app'
import { transformI18n } from '@/plugins/i18n'
import { buildShortUUID } from '@/utils/uuid'

const instance = getCurrentInstance()?.appContext.app.config.globalProperties
const menuMode = instance?.$storage.layout?.layout === 'vertical'
const pureApp = useAppStoreHook()

const props = defineProps({
  item: {
    type: Object as PropType<childrenType>,
    default: () => {},
  },
  isNest: {
    type: Boolean,
    default: false,
  },
  basePath: {
    type: String,
    default: '',
  },
})

const getNoDropdownStyle = computed((): CSSProperties => {
  return {
    display: 'flex',
    alignItems: 'center',
  }
})

const getDivStyle = computed((): CSSProperties => {
  return {
    width: pureApp.sidebar.opened ? '' : '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    overflow: 'hidden',
  }
})

const getMenuTextStyle = computed((): CSSProperties => {
  return {
    width: pureApp.sidebar.opened ? '125px' : '',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    outline: 'none',
  }
})

const getSubTextStyle = computed((): CSSProperties => {
  return {
    width: pureApp.sidebar.opened ? '125px' : '',
    display: 'inline-block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }
})

const getSpanStyle = computed((): CSSProperties => {
  return {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }
})

const onlyOneChild: childrenType = ref({})
// 存放菜单是否存在showTooltip属性标识
const hoverMenuMap = new WeakMap()
// 存储菜单文本dom元素
const menuTextRef: Ref<Nullable<HTMLElement>> = ref(null)

function hoverMenu(key: childrenType) {
  // 如果当前菜单showTooltip属性已存在，退出计算
  if (hoverMenuMap.get(key)) return

  nextTick(() => {
    // 如果文本内容的整体宽度大于其可视宽度，则文本溢出
    if (menuTextRef?.value && menuTextRef.value.scrollWidth > menuTextRef.value.clientWidth) {
      Object.assign(key, {
        showTooltip: true,
      })
    } else {
      Object.assign(key, {
        showTooltip: false,
      })
    }

    hoverMenuMap.set(key, true)
  })
}

function hasOneShowingChild(children: childrenType[] = [], parent: childrenType) {
  const showingChildren = children.filter((item: any) => {
    onlyOneChild.value = item
    return true
  })

  if (showingChildren.length === 1) {
    return true
  }

  if (showingChildren.length === 0) {
    onlyOneChild.value = { ...parent, noShowingChildren: true }
    return true
  }
  return false
}

function resolvePath(routePath: string | undefined) {
  return routePath || buildShortUUID()
  // if (!routePath) return '/'
  // const httpReg = /^http(s?):\/\//
  // if (httpReg.test(routePath)) {
  //   return routePath
  // } else {
  //   return routePath
  // }
}
</script>

<template>
  <template
    v-if="
      hasOneShowingChild(props.item.children, props.item) &&
      (!onlyOneChild.children || onlyOneChild.noShowingChildren)
    "
  >
    <el-menu-item
      :index="resolvePath(onlyOneChild.path)"
      :class="{ 'submenu-title-noDropdown': !isNest }"
      :style="getNoDropdownStyle"
    >
      <el-icon v-show="props.item.meta?.icon" :size="18">
        <component :is="onlyOneChild.meta?.icon || props.item.meta?.icon"></component>
      </el-icon>

      <template #title>
        <div :style="getDivStyle">
          <span v-if="!menuMode">{{
            transformI18n(onlyOneChild.meta?.title!, onlyOneChild.meta?.i18n)
          }}</span>
          <el-tooltip v-else placement="top" :offset="-10" :disabled="!onlyOneChild.showTooltip">
            <template #content>
              {{ transformI18n(onlyOneChild.meta?.title!, onlyOneChild.meta?.i18n) }}
            </template>
            <span ref="menuTextRef" :style="getMenuTextStyle" @mouseover="hoverMenu(onlyOneChild)">
              {{ transformI18n(onlyOneChild.meta?.title!, onlyOneChild.meta?.i18n) }}
            </span>
          </el-tooltip>
        </div>
      </template>
    </el-menu-item>
  </template>

  <el-sub-menu v-else ref="subMenu" :index="resolvePath(props.item?.path)" teleported>
    <template #title>
      <el-icon v-show="props.item.meta?.icon">
        <component :is="props.item.meta?.icon"></component>
      </el-icon>
      <span v-if="!menuMode">
        {{ transformI18n(props.item.meta?.title!, props.item.meta?.i18n) }}</span
      >
      <el-tooltip
        v-else
        placement="top"
        :offset="-10"
        :disabled="!pureApp.sidebar.opened || !props.item?.showTooltip"
      >
        <template #content>
          {{ transformI18n(props.item.meta?.title!, props.item.meta?.i18n) }}
        </template>
        <div ref="menuTextRef" :style="getSubTextStyle" @mouseover="hoverMenu(props.item!)">
          <span :style="getSpanStyle">
            {{ transformI18n(props.item.meta?.title!, props.item.meta?.i18n) }}
          </span>
        </div>
      </el-tooltip>
    </template>
    <sidebar-item
      v-for="child in props.item?.children"
      :key="child.path"
      :is-nest="true"
      :item="child"
      :base-path="child.path"
      class="nest-menu"
    />
  </el-sub-menu>
</template>
