<template>
  <section
    :class="[props.fixedHeader ? 'app-main' : 'app-main-nofixed-header']"
    :style="getSectionStyle"
  >
    <router-view>
      <template #default="{ Component, route }">
        <el-scrollbar v-if="props.fixedHeader">
          <el-backtop title="回到顶部" target=".app-main .el-scrollbar__wrap">
            <backTop />
          </el-backtop>
          <transitionMain :route="route">
            <div>
              <div v-if="route.name !== 'dashboard'" class="flex-1 bg-white p-4">
                <div class="flex justify-between mb-4">
                  <div>
                    <p class="font-bold text-xl">
                      {{ transformI18n(route.meta?.title as string, route.meta?.i18n as boolean) }}
                    </p>
                  </div>
                </div>
              </div>
              <keep-alive v-if="keepAlive" :include="usePermissionStoreHook().cachePageList">
                <suspense>
                  <component :is="Component" :key="route.fullPath" class="main-content" />
                </suspense>
              </keep-alive>
              <suspense v-else>
                <component :is="Component" :key="route.fullPath" class="main-content" />
              </suspense>
            </div>
          </transitionMain>
        </el-scrollbar>
        <div v-else>
          <transitionMain :route="route">
            <div>
              <div v-if="route.name !== 'dashboard'" class="flex-1 bg-white p-4">
                <div class="flex justify-between mb-4">
                  <div>
                    <p class="font-bold text-xl">
                      {{ transformI18n(route.meta?.title as string, route.meta?.i18n as boolean) }}
                    </p>
                  </div>
                </div>
              </div>
              <keep-alive v-if="keepAlive" :include="usePermissionStoreHook().cachePageList">
                <suspense>
                  <component :is="Component" :key="route.fullPath" class="main-content" />
                </suspense>
              </keep-alive>
              <suspense v-else>
                <component :is="Component" :key="route.fullPath" class="main-content"
              /></suspense>
            </div>
          </transitionMain>
        </div>
      </template>
    </router-view>
  </section>
</template>
<script setup lang="ts">
import {
  h,
  ref,
  computed,
  Transition,
  defineComponent,
  getCurrentInstance,
  onMounted,
  nextTick,
} from 'vue'
import { onBeforeRouteLeave, RouteLocationNormalizedLoaded, RouterView } from 'vue-router'
import backTop from '@/assets/icons/back-top.svg?component'
import { usePermissionStoreHook } from '@/store/modules/permission'
import { Opportunity } from '@element-plus/icons-vue'
import { transformI18n } from '@/plugins/i18n'

const visible = ref(false)

const props = defineProps({
  fixedHeader: Boolean,
})
const keepAlive: Boolean = ref(
  getCurrentInstance()?.appContext.config.globalProperties.$config?.KeepAlive
)
const instance = getCurrentInstance()?.appContext.app.config.globalProperties.$storage

const transitions = computed(() => {
  return (route: any) => {
    return route.meta.transition
  }
})

const hideTabs = computed(() => {
  return instance?.configure.hideTabs
})

const layout = computed(() => {
  return instance?.layout.layout === 'vertical'
})

const getSectionStyle = computed(() => {
  return [
    hideTabs.value && layout ? 'padding-top: 48px;' : '',
    !hideTabs.value && layout ? 'padding-top: 85px;' : '',
    hideTabs.value && !layout.value ? 'padding-top: 48px' : '',
    !hideTabs.value && !layout.value ? 'padding-top: 85px;' : '',
    props.fixedHeader ? '' : 'padding-top: 0;',
  ]
})

const transitionMain = defineComponent({
  props: {
    route: {
      type: Object as PropType<RouteLocationNormalizedLoaded>,
      required: true,
    },
  },
  render() {
    const slots = this.$slots
    return h(
      Transition,
      {
        name:
          transitions.value(this.route) && (this.route?.meta?.transition as any).enterTransition
            ? 'pure-classes-transition'
            : (transitions.value(this.route) && (this.route?.meta?.transition as any).name) ||
              'fade-transform',
        enterActiveClass:
          transitions.value(this.route) &&
          `animate__animated ${(this.route?.meta?.transition as any).enterTransition}`,
        leaveActiveClass:
          transitions.value(this.route) &&
          `animate__animated ${(this.route?.meta?.transition as any).leaveTransition}`,
        mode: 'out-in',
        appear: true,
      },
      {
        default: () => [slots.default!()],
      }
    )
  },
})

const warningsRef = ref()

onMounted(() => {})
</script>

<style scoped>
.app-main {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow-x: hidden;
}

.app-main-nofixed-header {
  width: 100%;
  min-height: 100vh;
  position: relative;
}
.warnings {
  position: fixed;
  top: calc(50vh - 32px);
  z-index: 2022;
  left: calc(100vw - 63px);
}
</style>
