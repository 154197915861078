import type { OrgInfo, UseNameInfo } from './model/Sync'
import { http } from '@/utils/http'

enum Api {
  GetCompanyList = '/sync/getCompanyList',
  GetOrgList = '/sync/getOrgList',
  QueryUseInfoByIds = '/sync/queryUseInfoByIds',
  QueryUseInfos = '/sync/queryUseInfos',
  GetCollectionModelNames = '/sync/getCollectionModelNames',
}

// 公司列表
export function getCompanyList() {
  return http.get<any[]>({
    url: Api.GetCompanyList,
  })
}

/**
 * 根据区域IDs获取区域下机构
 * @returns
 */
export function getOrgList(regionIds: number[] | string[]) {
  return http.post<OrgInfo[]>({ url: Api.GetOrgList, data: regionIds })
}

/**
 * 根据名称ID获取名称
 */
export function queryUseInfoByIds(nameIds: number[] | string[]) {
  return http.post<UseNameInfo[]>({
    url: Api.QueryUseInfoByIds,
    data: nameIds,
  })
}

/**
 *
 * @param useId 用途ID
 * @param keyWord  关键字
 */
export function queryUseInfos(useId: string | number, keyWord = '') {
  return http.get<UseNameInfo[]>({
    url: Api.QueryUseInfos,
    params: { useId, keyWord },
  })
}

/**
 * 获取收款方式ID名
 */
export function getCollectionModelNames(ids: number[] = []) {
  return http.post<{ systemId: number; name: string }[]>({
    url: Api.GetCollectionModelNames,
    data: ids || [],
  })
}
