<script setup lang="ts">
import { computed } from 'vue'
import { i18n } from '@/plugins/i18n'
import { emitter } from '@/utils/mitt'
import Notice from './notice/index.vue'
import avatars from '@/assets/avatars.png'
import Hamburger from './sidebar/hamBurger.vue'
import { useRouter, useRoute } from 'vue-router'
import { storageSession } from '@/utils/storage'
import Breadcrumb from './sidebar/breadCrumb.vue'
import { useAppStoreHook } from '@/store/modules/app'
import { useUserStoreHook } from '@/store/modules/user'
import { unref, watch, getCurrentInstance } from 'vue'
import { deviceDetection } from '@/utils/deviceDetection'
import screenfull from '../components/screenfull/index.vue'
import { useEpThemeStoreHook } from '@/store/modules/epTheme'
// import globalization from '@/assets/icons/globalization.svg?component'

const instance = getCurrentInstance()?.appContext.config.globalProperties.$storage
const appStore = useAppStoreHook()
const router = useRouter()
const route = useRoute()
const userStore = useUserStoreHook()
let username = userStore.userInfo?.name
const { locale } = i18n.global

// const getDropdownItemStyle = computed(() => {
//   return (t: string) => {
//     return {
//       background: locale.value === t ? useEpThemeStoreHook().epThemeColor : '',
//       color: locale.value === t ? '#f4f4f5' : '#000',
//     }
//   }
// })

watch(
  () => locale.value,
  () => {
    //@ts-ignore
    unref(route.meta.title)
  }
)

// 退出登录
const logout = (): void => {
  storageSession.removeItem('info')
  userStore.logout()
}

function onPanel() {
  emitter.emit('openPanel', '')
}

function toggleSideBar() {
  appStore.toggleSideBar()
}

// 简体中文
function translationCh() {
  instance.locale = { locale: 'zh' }
  locale.value = 'zh'
}

// English
function translationEn() {
  instance.locale = { locale: 'en' }
  locale.value = 'en'
}
</script>

<template>
  <div class="navbar">
    <Hamburger
      :is-active="appStore.sidebar.opened"
      class="hamburger-container"
      @toggle-click="toggleSideBar"
    />

    <Breadcrumb class="breadcrumb-container" />

    <div class="vertical-header-right">
      <!-- 通知 -->
      <!-- <Notice id="header-notice" /> -->
      <!-- 全屏 -->
      <screenfull v-show="!deviceDetection()" id="header-screenfull" />
      <!-- 国际化 -->
      <!-- <el-dropdown id="header-translation" trigger="click">
        <globalization />
        <template #dropdown>
          <el-dropdown-menu class="translation">
            <el-dropdown-item :style="getDropdownItemStyle('zh')" @click="translationCh"
              ><icon
                v-show="locale === 'zh'"
                class="check-zh"
                icon="check"
              />简体中文</el-dropdown-item
            >
            <el-dropdown-item :style="getDropdownItemStyle('en')" @click="translationEn"
              ><icon v-show="locale === 'en'" class="check-en" icon="ep:check" 
                ></icon
              >English</el-dropdown-item
            >
          </el-dropdown-menu>
        </template>
      </el-dropdown> -->
      <!-- 退出登陆 -->
      <el-dropdown trigger="click">
        <span class="el-dropdown-link">
          <img :src="avatars" />
          <p class="ml-2">{{ username }}</p>
        </span>
        <template #dropdown>
          <el-dropdown-menu class="logout">
            <el-dropdown-item @click="logout">
              <icon icon="ri:logout-box-r-line" class="m-5px" />{{
                $t('buttons.hsLoginOut')
              }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <icon
        class="icon-setting"
        :title="$t('buttons.hssystemSet')"
        icon="ri:settings-line"
        :size="18"
        @click="onPanel"
      >
      </icon>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/layout/theme/default-vars.scss';
$navbarTextColor: $navbarTextColor;
$navbarTextHover: $navbarTextHover;
.navbar {
  width: 100%;
  height: 48px;
  overflow: hidden;
  background: $navbarBgColor;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  .hamburger-container {
    line-height: 48px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;
    color: $navbarTextColor;
  }

  .vertical-header-right {
    display: flex;
    min-width: 280px;
    height: 48px;
    align-items: center;
    color: $navbarTextColor;
    justify-content: flex-end;

    :deep(.dropdown-badge) {
      &:hover {
        background: $navbarTextHover;
      }
    }

    .screen-full {
      cursor: pointer;

      &:hover {
        background: $navbarTextHover;
      }
    }

    .globalization {
      height: 48px;
      width: 40px;
      padding: 11px;
      cursor: pointer;

      &:hover {
        background: $navbarTextHover;
      }
    }

    .el-dropdown-link {
      min-width: 100px;
      height: 48px;
      padding: 0 10px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      cursor: pointer;
      color: $navbarTextColor;

      &:hover {
        background: $navbarTextHover;
      }

      p {
        font-size: 14px;
      }

      img {
        width: 22px;
        height: 22px;
        border-radius: 50%;
      }
    }

    .icon-setting {
      height: 48px;
      width: 38px;
      padding: 10px;
      display: flex;
      cursor: pointer;
      align-items: center;

      &:hover {
        background: $navbarTextHover;
      }
    }
  }

  .breadcrumb-container {
    float: left;
  }
}

.translation {
  ::v-deep(.el-dropdown-menu__item) {
    padding: 5px 40px;
  }

  .check-zh {
    position: absolute;
    left: 20px;
  }

  .check-en {
    position: absolute;
    left: 20px;
  }
}

.logout {
  max-width: 120px;

  ::v-deep(.el-dropdown-menu__item) {
    min-width: 100%;
    display: inline-flex;
    flex-wrap: wrap;
  }
}
</style>
