import { initRouter } from '@/router/utils'
import { defineStore } from 'pinia'
import { store } from '@/store'
import { UserInfo, userType } from './types'
import { login, getUserInfo } from '@/api/auth'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { useMultiTagsStoreHook } from '@/store/modules/multiTags'
import { loginParams } from '@/api/model/authModel'
import router from '@/router'
import { routerArrays } from '@/layout/types'
import { ElMessageBox } from 'element-plus'
import { UserModel } from '@/api/model/UserModel'
import { useOrgStoreHook } from './org'

export const useUserStore = defineStore({
  id: 'pure-user',
  state: (): userType => ({
    token: getToken(),
    userInfo: null,
  }),
  actions: {
    setToken(token: string) {
      this.token = token
      setToken(token)
    },
    setUserInfo(userInfo: UserInfo) {
      this.userInfo = userInfo
    },
    // 登入
    async login(params: loginParams) {
      return new Promise<void>((resolve, reject) => {
        removeToken()
        login(params)
          .then((data) => {
            if (data) {
              this.setToken(data)
              this.getUserInfo().then(() => {
                resolve()
              })
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    async loginByToken(token: string) {
      return new Promise<void>((resolve, reject) => {
        removeToken()
        this.setToken(token)
        this.getUserInfo().then(() => {
          resolve()
        })
      })
    },
    //获取用户信息
    async getUserInfo() {
      return new Promise<UserModel>((resolve, reject) => {
        getUserInfo()
          .then((resp) => {
            const user = resp as UserInfo
            console.log('user: ', user)
            user.isAdmin = true
            //加载名下机构信息
            useOrgStoreHook().getRegionList(user.regionIds || '')
            useOrgStoreHook().getAllCompany()
            initRouter(user).then(() => {
              this.setUserInfo(user)
              resolve(user)
            })
          })
          .catch((error) => {
            this.logout()
            reject(error)
          })
      })
    },
    // 登出 清空缓存
    async logout() {
      this.token = ''
      removeToken()
      useMultiTagsStoreHook().handleTags('equal', [...routerArrays])
      const type = await ElMessageBox.confirm('登录失效请重新登录?')
      if (type === 'confirm') {
        router.push('/login')
      }
    },
  },
})

export function useUserStoreHook() {
  return useUserStore(store)
}
