import Layout from '@/layout/index.vue'
import { $t } from '@/plugins/i18n'

const settingRouter = {
  path: '/setting',
  name: 'setting',
  component: Layout,
  redirect: '/setting/store',
  meta: {
    icon: 'House',
    title: $t('menus.setting'),
    i18n: true,
    rank: 2,
  },
  children: [
    {
      path: '/setting/market',
      name: 'settingStore',
      component: () => import('@/views/setting/store.vue'),
      meta: {
        title: $t('menus.setting-store'),
        i18n: true,
        icon: 'OfficeBuilding',
        rank: 201,
        isHidden: false,
      },
    },
  ],
}

export default settingRouter
