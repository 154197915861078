import { http } from '@/utils/http'
import { loginParams } from './model/authModel'
import { UserModel } from './model/UserModel'

enum Api {
  Login = '/login',
  GetUserInfo = '/getInfo',
  CheckLogin = '/checkLogin',
  ChangePwd = '/changePwd',
  GetQrStatus = '/getQrStatus',
  GetQrCode = '/getQrCode',
}

// 登录
export const login = (params: loginParams) => {
  return http.post<string>({ url: Api.Login, params })
}

/**
 * 获取用户信息
 * @returns
 */
export function getUserInfo() {
  return http.get<UserModel>({ url: Api.GetUserInfo })
}

export function checkLogin(token: string) {
  return http.get<boolean>({ url: Api.CheckLogin, params: { token } })
}

export function changePwd(pwdNew: string, pwdNewConfirm: string, pwdOld: string) {
  return http.put<void>({ url: Api.ChangePwd, data: { pwdNew, pwdNewConfirm, pwdOld } })
}

export function getQrStatus(sign: string) {
  return http.get<{
    status: number
    token?: ''
  }>({ url: Api.GetQrStatus, headers: { sign } })
}

export function getQrCode(checkPath: string, timestamp: string | number, envVersion = 'release') {
  return http.get<any>(
    {
      url: Api.GetQrCode,
      params: { checkPath, timestamp, envVersion },
      responseType: 'blob',
    },
    {
      isReturnNativeResponse: true,
    }
  )
}
